.news-item {
  display: flex;
  justify-content: space-between;
  // flex-wrap: wrap;
}

.news-item-video {
  height: 400px;
}

.news-item-text {
  // margin: 12px 12px;
  flex: 1;
  overflow: hidden;
  background-color: white;
  padding: 21px;
  border-radius: 20px;

  &__date {
    font-size: 32px;
    margin: 10px 0;
  }
  &__title {
    font-weight: 700;
    font-size: 23px;
    margin: 10px 0;
  }
  &__description {
    font-size: 26px;
    font-weight: 400;
    margin: 10px 0;
  }

  &__details {
    & h3 {
      padding-top: -8px;
      margin-top: -10px;
    }
  }

  &__venue {
    font-size: 26px;
  }

  & ul {
    list-style: none;
  }
}

.news-item-image-container {
  width: 45%;
  // min-width: 300px;
  border-radius: 20px;
  overflow: hidden;
  max-height: 480px;
  // margin: 12px 12px;
}

.news-item-image {
  height: 110%;
  min-width: 110%;
  object-fit: cover;
  object-position: center;
}

iframe {
  width: 100%;
  height: 100%;
}

// @media (min-width: 701px) and (max-width: 1150px) {
//   // .news-item {
//   //   margin: 24px auto;
//   // }
//   .news-item-text {
//     width: 40%;
//     border-radius: 20px;
//     overflow: hidden;
//     // height: 320px;
//     // background-color: #eee9df;
//     padding: 25px;

//     &__line {
//       height: 7px;
//       background-color: #765721;
//     }
//     &__date {
//       font-size: 28px;
//       margin: 7px 0;
//     }
//     &__title {
//       font-weight: 700;
//       font-size: 28px;
//       margin: 7px 0;
//     }
//     &__description {
//       font-size: 25px;
//       font-weight: 400;
//       margin: 10px 0;
//     }
//     &__details {
//       font-size: 17px;
//     }
//     &__venue {
//       font-size: 25px;
//     }

//     & ul {
//       list-style: none;
//     }
//   }
//   .news-item-image-container {
//     width: 40%;
//     height: 373px;
//     // margin: 16px 0;
//   }
// }

// @media (max-width: 700px) {
//   .news-item {
//     padding: 20px;
//   }
//   .news-item-text {
//     width: 100%;
//     margin: 16px 0;

//     &__date {
//       font-size: 1.3rem;
//     }
//   }

//   .news-item-image-container {
//     width: 100%;
//     margin: 16px 0;
//   }
// }
