.portrait-contents {
  padding: 35px;
  max-width: 1000px;
  margin: auto;

  &__text {
    min-width: 500px;
    flex: 1;
  }

  &__image {
    position: relative;
    margin-bottom: 20px;
    height: 450px;
    width: 100%;
    overflow: hidden;
    background-color: white;
    border-radius: 20px;

    & img {
      width: 100%;
      object-fit: cover;
      object-position: 0px -60px;
      // height: 100%;
    }
  }
}

@media (max-width: 1100px) {
  .portrait-contents {
    // padding: 10px;
    &__image {
      height: 200px;
    }
    &__text {
      min-width: 90%;
      // margin: 24px;
    }
  }
}

@media (min-width: 701px) and (max-width: 1099px) {
  .portrait-contents {
    // padding: 15px;

    &__image {
      height: 300px;
    }
  }
}

@media (max-width: 700px) {
  .portrait-contents {
    padding: 15px;
  }
}
