.programme-card {
  margin: 1rem auto;
  display: flex;
  max-width: 1000px;

  &__image {
    width: 40%;
    width: 200px;
    height: 200px;
    margin-right: 1rem;
    overflow: hidden;

    & img {
      object-fit: cover;
      object-position: center;
      width: 220px;
      min-height: 220px;
    }
  }
}

.programme-card-text {
  & h1 {
    font-size: 1.5rem;
    margin-bottom: -19px;
  }
  & h4 {
    font-size: 1.2rem;
    font-weight: 400;
  }
  width: 70%;
  &__title {
    font-weight: 900;
    font-size: 1.4rem;
  }

  &__subtitle {
    font-weight: 700;
  }
}

@media (max-width: 700px) {
  .programme-card {
    flex-direction: column;
    align-items: center;
    &__image {
      width: 60%;
    }
  }
  .programme-card-text {
    width: 100%;
  }
}
