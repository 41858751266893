.recording-card-outer-container {
  margin: 35px auto;
  background-color: white;
  border-radius: 20px;
  min-width: 900px;
  padding: 30px;
}

.recording-card-contents {
  display: flex;
  &__image {
    width: 50%;

    & img {
      width: 100%;
    }
  }
}

.recording-card-details {
  padding-left: 20px;
  width: 100%;

  &__title {
    font-size: 32px;
    font-weight: 900;
  }

  &__details {
    margin-top: -10px;
    font-size: 23px;
  }
}

.audio-controls-container {
  display: flex;
  flex-wrap: wrap;
}

.audio-control {
  margin: 1%;
  width: 47%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  &__text {
    font-size: 1rem;
  }
  &__controller {
    background-color: whitesmoke;
    margin-top: 5px;
    text-align: center;
    font-size: 0.8rem;
    border: 1px solid black;
    border-radius: 12px;
    width: 98%;
  }
}

.buynow-buttons {
  display: flex;
  align-items: center;
  &__label {
    font-size: 0.8rem;
  }

  &__button {
    background-color: black;
    color: white;
    text-decoration: none;
    margin: 0 5px;
    padding: 5px 12px;
    font-size: 0.8rem;
    font-weight: 700;
    border-radius: 5px;
    &:hover {
      background-color: rgb(91, 90, 90);
    }
  }
}

@media (max-width: 900px) {
  .audio-control {
    &__text {
      font-size: 0.8rem;
    }
  }
}

@media (max-width: 840px) {
  .audio-controls-container {
    flex-direction: column;
  }
  .audio-control {
    width: 100%;
  }
  .buynow-buttons {
    margin-top: 10px;
  }
}

@media (max-width: 700px) {
  .recording-card-outer-container {
    min-width: 80%;
    margin: 10px;
    padding: 20px;
  }
  .recording-card-contents {
    flex-direction: column;
    align-items: center;
    &__image {
      margin-bottom: 16px;
    }
  }
  .recording-card-details {
    padding-left: 0;
  }
}
