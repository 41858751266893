.hero-container {
  background-image: url("../../assets/hero-background.jpg");
  background-size: cover;
  color: white !important;
  height: 328px;
  display: flex;
  justify-content: center;
  margin-bottom: 44px;

  & img {
    align-self: flex-end;
    max-width: 40%;
    max-height: 120%;
    z-index: 1000;
  }
}

.hero-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: -20px;
  margin-bottom: -50px;
  &__text {
    font-size: 3.5rem;
    text-align: left;
    line-height: 3.4rem;
  }
}

@media (max-width: 1100px) {
  .hero-container {
    height: 30vh;
  }
  .hero-title {
    &__text {
      font-size: 3rem;
      line-height: 3.2rem;
    }
  }
}

@media (max-width: 700px) {
  .hero-container {
    height: 25vh;
    & img {
      max-width: 60%;
    }
  }
  .hero-title {
    &__text {
      font-size: 2rem;
      line-height: 2.3rem;
    }
  }
}
