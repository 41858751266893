.appbar-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  width: 100%;
  z-index: 1000;
}

.appbar-inner-container {
  height: 55px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #9e1619;
  color: white;
}

.appbar-title {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: capitalize;
  margin-left: 35px;
}

@media (max-width: 700px) {
  .appbar-container {
    z-index: 2000;
  }
}
