.programme-page-contents {
  padding: 35px;
}

.category-tile {
  background-color: whitesmoke;
  padding: 35px;
  border-radius: 12px;
  margin: 2%;
  width: 20%;
  min-width: 200px;
  cursor: pointer;

  &:hover {
    background-color: rgb(250, 238, 238);
  }

  &__label {
    font-weight: 600;
    font-size: 1.2rem;
  }
}

.tiles-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cat-header {
  position: sticky;
  top: 60px;
  padding: 10px 25px;
  z-index: 700;
  background-color: #e9dabd;
  max-width: 1000px;
  margin: auto;
}

.cat-header-light {
  position: sticky;
  top: 60px;
  padding: 10px 25px;
  z-index: 700;
  background-color: white;
  border-radius: 20px;
  max-width: 1000px;
  margin: auto;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: auto;
  margin-bottom: 25px;
}

@media (max-width: 700px) {
  .programme-page-contents {
    padding: 15px;
  }
  .buttons-container {
    width: 100%;
  }
  .category-tile {
    width: 80%;
    text-align: center;
  }
}
