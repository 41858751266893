.contact-container {
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  color: #ac2426;
  text-align: center;

  & a {
    text-decoration: none;
    max-width: 100%;
  }
}

.contact-data-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 550px) {
  .contact-container {
    width: 210px;
    padding: 20px;
    font-size: 0.9rem;
  }
}
